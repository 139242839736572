<script setup lang="ts">
import * as zod from 'zod'

const schema = zod.object({
  email: zod.string().email(),
  password: zod.string().min(1),
})

const state = reactive({
  email: '',
  password: '',
})

const error = ref('')
const loading = ref(false)

const profile = useProfile()
const refreshToken = useRefreshToken()
const token = useToken()

async function handleSubmit(data: zod.infer<typeof schema>) {
  loading.value = true
  error.value = ''

  try {
    const response = await api.auth.login(data)

    if (!response.success)
      throw new ApiError(response)

    if (response.data.roleId !== 1)
      throw new Error('invalid-role')

    profile.value = response.data
    token.value = response.data.access_token
    refreshToken.value = response.data.refresh_token

    navigateTo('/')
  }

  catch (err: any) {
    const message = err?.response?.message ?? undefined
    const status = Number.parseInt(err?.response?.statusCode, 10)
      || err?.response?.status
      || undefined

    if (status === 400 || message === 'AUTH.LOGIN_PASSWORD_INCORRECT' || message === 'AUTH.LOGIN_EMAIL_NOT_FOUND') {
      error.value = 'invalid-credentials'
    }
    else if (err === 'invalid-role') {
      error.value = 'invalid-role'
    }
    else {
      error.value = 'unknown-error'
      console.error(err)
    }
  }
  finally {
    loading.value = false
  }
}

definePageMeta({
  layout: 'auth',
  colorMode: 'light',
})

useServerSeoMeta({
  title: 'Sign In',
})
</script>

<template>
  <div class="px-6">
    <div class="mb-12 px-2 pt-4">
      <img
        src="/logo.svg"
        alt="AppGuide UI"
        class="h-8"
      >
    </div>

    <main class="mx-auto max-w-sm flex flex-col">
      <h1 class="heading-3">
        Sign in
      </h1>

      <div class="my-10">
        <AuthGoogleProvider>
          Continue with Google
        </AuthGoogleProvider>

        <div class="relative my-8 flex items-center justify-center">
          <p class="relative z-10 bg-white px-4 text-center text-gray-500 leading-none">
            or
          </p>
          <div class="absolute inset-x-0 bottom-1.5 border-b-2 border-gray-200" />
        </div>

        <Callout v-if="error" class="mb-6">
          {{ error }}
        </Callout>

        <Form :state="state" :schema="schema" class="flex flex-col gap-y-6" @submit="handleSubmit">
          <TextInput
            v-model="state.email"
            name="email"
            type="email"
            label="Email"
            placeholder="example@therappx.com"
          />
          <TextInput
            v-model="state.password"
            name="password"
            type="password"
            label="Password"
            placeholder="••••••••"
          />

          <OldButton type="submit" :loading="loading" class="mt-6">
            Sign in
          </OldButton>
        </Form>
      </div>
    </main>
  </div>
</template>
