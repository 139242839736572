import revive_payload_client_OxfmVI0wEB from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vh4kL2LKmJ from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_T3ZrqCSt44 from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_gaitVpsifP from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_dPhbIitoLJ from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/docs/.nuxt/components.plugin.mjs";
import prefetch_client_gCv4rHV2HN from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/workspace/docs/.nuxt/unocss.mjs";
import plugin_client_w6660suYdO from "/workspace/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_Hwc3nHE08N from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_OxfmVI0wEB,
  unhead_vh4kL2LKmJ,
  router_T3ZrqCSt44,
  payload_client_gaitVpsifP,
  check_outdated_build_client_dPhbIitoLJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gCv4rHV2HN,
  unocss_MzCDxu9LMj,
  plugin_client_w6660suYdO,
  chunk_reload_client_Hwc3nHE08N
]