<script setup lang="ts">
defineProps<{
  disabled?: boolean
}>()

const { providers, getProviders } = useProviders()
onMounted(async () => {
  await getProviders('en')
})

const url = computed(() => providers.value.google.dev.en)
</script>

<template>
  <OldButton
    :to="url"
    :disabled="disabled"
    level="1"
    class="w-full"
  >
    <span class="size-10 flex flex-none items-center justify-center rounded-full bg-white -ml-5">
      <img src="/auth-provider-google.svg" width="24" height="24">
    </span>

    <span class="flex flex-1 items-center justify-center">
      Continue with Google
    </span>
  </OldButton>
</template>
