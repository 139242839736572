import { $fetch } from 'ofetch'
import type { FetchOptions } from 'ofetch'

function appguide<T = AppGuide.ApiResponse>(endpoint: string, arg?: FetchOptions): Promise<T> {
  const token = useCookie('token')
  const config = useRuntimeConfig()

  return $fetch(endpoint, {
    ...arg,
    baseURL: config.public.api,
    onRequest({ options }) {
      if (!token.value)
        return

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token.value}`,
      }
    },
  })
}

export class ApiError<T = AppGuide.ApiResponse> extends Error {
  response: T

  constructor(response: T, message?: string) {
    super(message || 'API Error Occurred')
    this.name = 'ApiError'
    this.response = response
  }
}

export const api = {
  auth: {
    profile() {
      return appguide<AppGuide.Auth.ProfileResponse>('/users/me')
    },
    login(body: { email: string, password: string }) {
      return appguide<AppGuide.Auth.LoginResponse>('/auth/login', {
        method: 'POST',
        body,
      })
    },
    logout() {
      return appguide('/auth/logout')
    },
    refresh(body: { refreshToken: string }) {
      return appguide<AppGuide.Auth.RefreshResponse>('/auth/refresh', {
        method: 'POST',
        body,
      })
    },
    provider: {
      list(params: { type: 'dev', language: AppGuide.Locales, endpoint: string }) {
        return appguide<AppGuide.Auth.ProviderListResponse>('/auth/oauth/url', {
          method: 'GET',
          params,
        })
      },
    },
  },
}
