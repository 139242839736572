<script setup lang="ts">
import { computed } from 'vue'
import { Label } from 'radix-vue'

interface Props {
  id: string
  required?: boolean
  label?: string
  hint?: string
  description?: string
  disabled?: boolean
  readonly?: boolean
  error?: string | boolean
  help?: string
  size?: 'sm' | 'base' | 'lg' | 'xl'
  fieldset?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
})

const elements = computed(() => {
  if (props.fieldset) {
    return {
      root: 'fieldset',
      label: 'legend',
    }
  }

  return {
    root: 'div',
    label: Label,
  }
})
</script>

<template>
  <component :is="elements.root" class="field">
    <div
      v-if="label || $slots.label"
      class="flex flex-wrap justify-between"
      :class="{
        'mb-2': !description && !$slots.description,
      }"
    >
      <component
        :is="elements.label"
        v-if="label || $slots.label"
        :for="id"
        class="field-label text-form-label"
        :class="{
          'text-field-label-sm': size === 'sm',
          'text-field-label': size === 'base',
          'text-field-label-lg': size === 'lg',
          'text-field-label-xl': size === 'xl',
        }"
      >
        <slot name="label">
          {{ label }}
        </slot>
        <span v-if="required" class="text-form-error">*</span>
      </component>
      <p
        v-if="hint || $slots.hint"
        class="text-form-description"
        :class="{
          'text-field-description-sm': size === 'sm',
          'text-field-description': size === 'base',
          'text-field-description-lg': size === 'lg',
          'text-field-description-xl': size === 'xl',
        }"
      >
        <slot name="hint">
          {{ hint }}
        </slot>
      </p>
    </div>

    <p
      v-if="description || $slots.description"
      class="mb-2 text-form-description"
      :class="{
        'text-field-description-sm': size === 'sm',
        'text-field-description': size === 'base',
        'text-field-description-lg': size === 'lg',
        'text-field-description-xl': size === 'xl',
      }"
    >
      <slot name="description">
        {{ description }}
      </slot>
    </p>

    <slot />

    <div v-if="error || help || $slots.help || $slots.rules" class="ml-4 mt-2">
      <p
        v-if="error && !disabled && !readonly"
        class="text-form-error"
        :class="{
          'text-field-label-sm': size === 'sm',
          'text-field-label': size === 'base',
          'text-field-label-lg': size === 'lg',
          'text-field-label-xl': size === 'xl',
        }"
      >
        <slot name="error">
          {{ error }}
        </slot>
      </p>
      <p
        v-else-if="help || $slots.help"
        class="text-form-description"
        :class="{
          'text-field-description-sm': size === 'sm',
          'text-field-description': size === 'base',
          'text-field-description-lg': size === 'lg',
          'text-field-description-xl': size === 'xl',
        }"
      >
        <slot name="help">
          {{ help }}
        </slot>
      </p>
    </div>
  </component>
</template>
