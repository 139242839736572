<script setup lang="ts">
import type { NavItem } from '@nuxt/content/dist/runtime/types'

const navigation = inject<Ref<NavItem[]>>('navigation')
</script>

<template>
  <div class="flex flex-col h-dvh">
    <header class="h-16 flex flex-none items-center border-b px-6 md:px-10">
      <NuxtLink to="/">
        <img
          src="/logo.svg"
          alt="AppGuide UI"
          width="121"
          height="24"
        >
      </NuxtLink>
    </header>

    <div class="grid grid-cols-[16rem,1fr] mx-auto max-w-7xl w-full gap-10 px-6">
      <aside class="sticky bottom-0 top-0 max-h-screen min-h-[calc(100vh-4rem)] overflow-y-auto border-r pt-6">
        <nav class="space-y-10">
          <div v-for="root in navigation" :key="`root-${root._path}`">
            <div class="mb-2 font-bold">
              {{ root.title }}
            </div>

            <nav class="flex flex-col pl-3">
              <NuxtLink
                v-for="child in root.children"
                :key="`child-${child._path}`"
                :to="child._path"
                exact-active-class="border-blue-500 text-blue-800"
                class="border-l py-1 pl-4 text-sm font-medium"
              >
                {{ child.title }}
              </NuxtLink>
            </nav>
          </div>
        </nav>

        <div class="h-16 w-full" />
      </aside>

      <slot />
    </div>

    <footer class="mt-auto min-h-16 flex flex-none items-center justify-center border-t text-center">
      Made with ❤️ by TherAppX team
    </footer>
  </div>
</template>
