<script setup lang="ts">
import { ref } from 'vue'
import { useFormInput } from '../../composables/useFormInput'
import type { FieldProps, FormInputProps } from '../../types'
import { useId } from '#app'

interface Props extends FieldProps, FormInputProps {
  name: string
  type?: 'text' | 'email' | 'password' | 'search' | 'number' | 'url' | 'date' | 'tel'
  placeholder?: string
  disabled?: boolean
  readonly?: boolean

  /**
   * Input element size (not Field size)
   * @default 'base'
   */
  size?: 'sm' | 'base' | 'lg' | 'xl'

  /**
   * HTML autocomplete attribute
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete?: 'on' | 'off' | string
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  size: 'base',
})

const id = useId()
const model = defineModel()
const input = ref<HTMLInputElement | null>(null)

const { error, emitFormBlur, emitFormChange, emitFormInput } = useFormInput(model, props)
</script>

<template>
  <Field :id="id" :disabled="disabled" :readonly="readonly" :error="error" :required="required">
    <template v-if="label || $slots.label" #label>
      <slot name="label">
        {{ label }}
      </slot>
    </template>
    <template v-if="hint || $slots.hint" #hint>
      <slot name="hint">
        {{ hint }}
      </slot>
    </template>
    <template v-if="description || $slots.description" #description>
      <slot name="description">
        {{ description }}
      </slot>
    </template>

    <div
      class="flex gap-3 border focus-within:ring-2"
      :class="{
        'px-[calc(1rem-1px)] rounded-lg': size === 'sm',
        'px-[calc(1rem-1px)] rounded-xl': size === 'base',
        'px-[calc(1.25rem-1px)] rounded-xl': size === 'lg',

        'bg-form-input-bg': !disabled && !readonly,
        'bg-form-input-bg_readonly': readonly,
        'bg-form-input-bg_disabled': disabled,

        'border-form-input-border': (!model || readonly) && !error,
        'border-form-input-border_filled': !!model && !readonly && !error,
        'focus-within:ring-form-input-ring_active': !error && !disabled && !readonly,
        'border-form-error focus-within:ring-form-input-ring_error': error && !disabled && !readonly,
        'hover:border-form-active focus-within:border-form-active': !error && !disabled && !readonly,
        'hover:shadow-input focus-within:shadow-input': !disabled && !readonly,
      }"
      @click.self="input?.focus()"
    >
      <div v-if="$slots.leading" class="flex items-center self-center">
        <slot name="leading" v-bind="{ disabled, readonly, value: model }" />
      </div>

      <input
        :id="id"
        ref="input"
        v-model="model"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled || readonly"
        :readonly="readonly"
        :autocomplete="autocomplete"
        class="w-full focus:outline-none placeholder-gray-500"
        :class="{
          'py-[calc(0.75rem-1px)] text-field-description-sm': size === 'sm',
          'py-[calc(0.75rem-1px)] text-field-description': size === 'base',
          'py-[calc(1rem-1px)] text-field-description-lg': size === 'lg',

          'bg-form-input-bg': !disabled && !readonly,
          'bg-form-input-bg_readonly': readonly,
          'bg-form-input-bg_disabled text-gray-400': disabled,
          'text-form-label': !disabled,
        }"
        @blur="emitFormBlur"
        @input="emitFormInput"
        @change="emitFormChange"
      >

      <div v-if="$slots.trailing" class="flex items-center self-center">
        <slot name="trailing" v-bind="{ disabled, readonly, value: model }" />
      </div>
    </div>

    <template v-if="error || $slots.error" #error>
      <slot name="error">
        {{ error }}
      </slot>
    </template>
    <template v-if="help || $slots.help" #help>
      <slot name="help">
        {{ help }}
      </slot>
    </template>
  </Field>
</template>

<style scoped>
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
