<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import type { RouteLocation } from 'vue-router'
import type { ComputedOptions, ConcreteComponent, MethodOptions, PropType } from 'vue'

interface Props {
  level?: '1' | '2' | '3' | '4' | 1 | 2 | 3 | 4
  variant?: 'blue' | 'red' | 'gray' | 'ghost' | 'ghost-white' | 'ghost-inverted'

  disabled?: boolean
  loading?: boolean

  to?: RouteLocation | string
  type?: 'submit' | 'reset' | 'button'
}

interface ComponentElement<T = Record<string, unknown>> {
  is: string | ConcreteComponent<object, any, any, ComputedOptions, MethodOptions>
  props: T
}

const props = withDefaults(defineProps<Props>(), {
  level: 2,
  variant: 'blue',
})

const element = computed<ComponentElement>(() => {
  if (props.to)
    return linkElement()

  return buttonElement()
})

function buttonElement(): ComponentElement {
  return {
    is: 'button',
    props: {
      type: props.type || 'button',
      disabled: props.disabled || props.loading,
    },
  }
}

function linkElement(): ComponentElement {
  return {
    is: resolveComponent('NuxtLink'),
    props: {
      to: props.to,
    },
  }
}
</script>

<template>
  <component
    :is="element.is"
    v-bind="element.props"
    class="ui-button"
    :class="[{
      loading,
      'level-1': props.level === 1 || props.level === '1',
      'level-2': props.level === 2 || props.level === '2',
      'level-3': props.level === 3 || props.level === '3',
      'level-4': props.level === 4 || props.level === '4',
    }, variant]"
  >
    <slot />

    <i
      v-if="loading"
      class="ui-button-spinner i-appguide-spinner absolute animate-spin text-gray-700"
    />
  </component>
</template>

<style lang="scss" scoped>
.ui-button {
  @apply relative flex items-center justify-center gap-2 transition rounded-full;

  &.level-1 {
    @apply font-display text-sm font-semibold px-6 py-3 h-12;

    @screen md {
      @apply text-base;

      height: 3.25rem;
    }
  }

  &.level-2 {
    @apply text-sm font-bold px-10 py-2 h-10;

    @screen md {
      @apply text-base h-11;
    }
  }

  &.level-3 {
    @apply text-sm font-bold px-6 py-1.5 h-9;

    @screen md {
      @apply text-base h-10;
    }
  }

  &.level-4 {
    @apply text-sm font-semibold px-4 py-1.5 h-7;

    @screen md {
      @apply text-base h-8;
    }
  }

  & :deep(i:not(.ui-button-spinner)) {
    @apply size-4;
  }

  &.level-1,
  &.level-2 {
    :deep(i:not(.ui-button-spinner)) {
      @screen md {
        @apply size-5;
      }
    }
  }

  &:focus {
    @apply ring outline-none;
  }

  &.blue {
    @apply bg-blue-500 ring-blue-500/20 text-white;

    &:hover {
      @apply bg-blue-700;
    }
  }

  &.red {
    @apply bg-red-500 ring-red-500/20 text-white;

    &:hover {
      @apply bg-red-700;
    }
  }

  &.gray {
    @apply bg-gray-300 ring-gray-300/30 text-gray-700;

    &:hover {
      @apply bg-gray-400;
    }
  }

  &.ghost {
    @apply bg-transparent ring-gray-400/10 text-gray-700 border-2 border-gray-300;

    &:hover {
      @apply border-blue-500 text-blue-500;
    }

    &:disabled {
      @apply bg-transparent border-gray-200;

      &:hover {
        @apply bg-transparent;
      }
    }
  }

  &.ghost-white {
    @apply bg-white ring-gray-400/10 text-gray-700 border-2 border-gray-300;

    &:hover {
      @apply bg-white border-blue-500 text-blue-500;
    }

    &:disabled {
      @apply bg-white border-gray-200;

      &:hover {
        @apply bg-white;
      }
    }
  }

  &.ghost-inverted {
    @apply bg-transparent ring-gray-400/10 text-white border-2 border-white;

    &:hover {
      @apply bg-blue-500 border-blue-500;
    }

    &:disabled {
      @apply border-white/60 text-white/60 bg-transparent;

      &:hover {
        @apply bg-transparent;
      }
    }
  }

  &:disabled {
    @apply bg-gray-200 text-gray-400 cursor-not-allowed;

    &:hover {
      @apply bg-gray-200;
    }
  }

  &.loading {
    @apply text-transparent cursor-progress;

    &:hover {
      @apply text-transparent;
    }
  }

  .ui-button-spinner {
    @apply size-6;
  }
}
</style>
