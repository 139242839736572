import { default as _91_46_46_46slug_93pDJwsx5N2uMeta } from "/workspace/docs/pages/[...slug].vue?macro=true";
import { default as loginOx8a1gh8AfMeta } from "/workspace/docs/pages/auth/login.vue?macro=true";
import { default as oauthQMqch2mPvUMeta } from "/workspace/docs/pages/auth/oauth.vue?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4SMeta } from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4S } from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_ro_po3bequwe5dk6yfuc65rszlhdq/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93pDJwsx5N2uMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pDJwsx5N2uMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pDJwsx5N2uMeta || {},
    alias: _91_46_46_46slug_93pDJwsx5N2uMeta?.alias || [],
    redirect: _91_46_46_46slug_93pDJwsx5N2uMeta?.redirect,
    component: () => import("/workspace/docs/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: loginOx8a1gh8AfMeta?.name ?? "auth-login",
    path: loginOx8a1gh8AfMeta?.path ?? "/auth/login",
    meta: loginOx8a1gh8AfMeta || {},
    alias: loginOx8a1gh8AfMeta?.alias || [],
    redirect: loginOx8a1gh8AfMeta?.redirect,
    component: () => import("/workspace/docs/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: oauthQMqch2mPvUMeta?.name ?? "auth-oauth",
    path: oauthQMqch2mPvUMeta?.path ?? "/auth/oauth",
    meta: oauthQMqch2mPvUMeta || {},
    alias: oauthQMqch2mPvUMeta?.alias || [],
    redirect: oauthQMqch2mPvUMeta?.redirect,
    component: () => import("/workspace/docs/pages/auth/oauth.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4SMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4SMeta?.path ?? "/",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4SMeta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4SMeta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4SMeta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64unocss_43reset_640_4659_464_eslint_648_4657_460_floating_45vue_645_462EHVd3GZE4S
  }
]