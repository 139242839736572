import { computed, inject, ref } from 'vue'
import type { Ref } from 'vue'
import { type UseEventBusReturn, useDebounceFn } from '@vueuse/core'
import type { FormError, FormEvent, FormEventType, FormInputProps, InjectedFormFieldValue } from '../types'

export function useFormInput(value: Ref, props: FormInputProps) {
  const formErrors = inject<Ref<FormError[]> | null>('form-errors', null)

  const errors = computed(() => {
    if (formErrors)
      return formErrors.value.filter(error => error.path === props.name).map(error => error.message)

    return []
  })
  const error = computed(() => errors.value[0])

  const blurred = ref(false)

  const formBus = inject<UseEventBusReturn<FormEvent, string> | undefined>('form-events', undefined)
  function emitFormEvent(type: FormEventType, path?: string) {
    if (formBus)
      formBus.emit({ type, path, blurred: blurred.value })
  }

  function emitFormBlur() {
    blurred.value = true
    emitFormEvent('blur', props.name)
  }

  function emitFormChange() {
    emitFormEvent('change', props.name)
  }

  const emitFormInput = useDebounceFn(() => {
    emitFormEvent('input', props.name)
  }, 300)

  return {
    error,
    errors,

    emitFormInput,
    emitFormChange,
    emitFormBlur,
  }
}
