<script setup lang="ts">
const open = ref(false)
</script>

<template>
  <div>
    <header class="h-12 flex items-center border-b px-6 md:col-span-2 dark:border-gray-700">
      <img src="/logo.svg" width="121" height="24">
    </header>

    <div class="grid mx-auto max-w-7xl md:grid-cols-[256px_1fr]">
      <nav
        class="px-6 pt-10 md:sticky md:top-0 md:max-h-screen md:min-h-[calc(100vh-3rem)] md:border-r max-md:inset-0 max-md:max-w-[226px] max-md:bg-gray-900"
        :class="{
          'max-md:hidden': !open,
          'max-md:fixed': open,
        }"
      >
        <ContentNavigation v-slot="{ navigation }">
          <ul class="space-y-6">
            <li v-for="section of navigation" :key="section._path">
              <span v-if="section.children" class="font-bold">{{ section.title }}</span>
              <NuxtLink v-else :to="section._path" class="font-bold">
                {{ section.title }}
              </NuxtLink>

              <ul v-if="section.children" class="mt-2 flex flex-col pl-3">
                <li v-for="link of section.children" :key="link._path">
                  <NuxtLink
                    :to="link._path"
                    exact-active-class="border-blue-500 text-purple-700"
                    class="border-l py-1 pl-4 text-sm font-medium"
                  >
                    {{ link.title }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
        </ContentNavigation>
      </nav>

      <slot />
    </div>

    <footer class="mt-auto min-h-16 flex flex-none items-center justify-center border-t text-center">
      Made with ❤️ by TherAppX team
    </footer>
  </div>
</template>
