<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import { nextTick, onMounted, onUnmounted, provide, ref } from 'vue'
import type * as z from 'zod'
import type { FormError, FormEvent } from '../../types'
import { useId } from '#imports'

const props = defineProps<{
  schema: z.ZodSchema
  state: any
  initialState?: any
}>()

const emit = defineEmits<{
  (e: 'submit', data: z.infer<typeof props.schema>): void
  (e: 'reset'): void
}>()

const submited = ref(false)
provide('form-submited', submited)

const errors = ref<FormError[]>([])
provide('form-errors', errors)

const formId = useId()
const bus = useEventBus<FormEvent>(`form-${formId}`)
provide('form-events', bus)

// Input where the user has interacted with and
// we should display the error message if any
const dirtyInputs = new Set<string>()

onMounted(() => {
  bus.on(async (event) => {
    await nextTick()

    switch (event.type) {
      case 'input':
        if (submited.value || event.blurred)
          validate()

        if (event.path && event.blurred)
          dirtyInputs.add(event.path)
        break

      default:
        validate()

        if (event.path)
          dirtyInputs.add(event.path)
    }
  })
})

onUnmounted(() => {
  bus.reset()
})

function validate(): boolean {
  const result = props.schema.safeParse(props.state)

  if (result.success) {
    errors.value = []
    return true
  }

  const allErrors = result.error.issues
    .map(issue => ({
      path: issue.path.join('.'),
      message: issue.message,
    }))

  if (submited.value)
    errors.value = allErrors
  else
    errors.value = allErrors.filter(error => dirtyInputs.has(error.path))

  return false
}

function onSubmit() {
  submited.value = true
  validate() && emit('submit', props.state)
}

function onReset() {
  errors.value = []
  submited.value = false
  // state.value = props.initialState
  emit('reset')
}
</script>

<template>
  <form @submit.prevent="onSubmit" @reset.prevent="onReset">
    <slot v-bind="{ errors }" />
  </form>
</template>
